import { motion } from 'framer-motion';
import React from 'react';

interface Proops {
  children: React.ReactNode;
  duration: number;
}

const motionDown = ({ children, duration }: Proops) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: duration, ease: 'easeIn' }}
    >
      {children}
    </motion.div>
  );
};

export default motionDown;
