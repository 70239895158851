"use client"
import 'swiper/css';
import '../../../globals.css';
import Circle from './circle';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import MobileView from './mobileView';
import MediaSocial from './mediaSocial';
import { getDictionary } from '@/dictionaries';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import PaginationTopBanner from './paginationTopBanner';
import MotionRight from '@/lib/framerMotion/motionRight';
import { Box, Container, Button, Grid, Typography } from '@mui/material';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import LargeView from './largeView';

const SliderTopBanner = ({ params }: { params: { lang: string } }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };

    fetchDictionary();
  }, [params.lang]);

  const dataSlider = [
    {
      id: 1,
      src: '/img/Background-1.png',
      title: dictionary.carouselTitle1,
      buttonText: dictionary.buttonCarousel1,
      to: 'about'
    },
    {
      id: 2,
      src: '/img/Background-2.png',
      title: dictionary.carouselTitle2,
      buttonText: dictionary.buttonCarousel2,
      to: 'contact'
    },
    {
      id: 3,
      src: '/img/Background-3.png',
      title: dictionary.carouselTitle3,
      buttonText: dictionary.buttonCarousel3,
      to: 'shop'
    },
  ]
  return (
    <Swiper
      effect={'fade'}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 6500,
        disableOnInteraction: false,
      }}
      fadeEffect={{
        crossFade: true,
      }}
      speed={1000}
      onSlideChange={(swiper) => {
        const active = swiper.realIndex;
        setActiveIndex(active)
      }}
      modules={[Autoplay, EffectFade]}
    >
      {
        dataSlider.map((item, index) => (
          <SwiperSlide
            key={index}
            className="imageBox"
            style={{
              backgroundImage: `url(${item.src})`,
              position: 'relative',
              zIndex: 2,
              height: '105vh'
            }}
          >
            <Container maxWidth="lg" className="boxCarousel">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <MotionRight duration={1.5}>
                      <Typography className="titleHero">
                        {item.title}
                      </Typography>
                    </MotionRight>
                  </Grid>
                </Grid>
                <Grid container className="boxCarouselItem" sx={{ bottom: 50, position: 'fixed' }} >
                  <Grid item xs={12} lg={5}>
                    <MotionRight duration={1.4}>
                      <Button className="buttonCarousel">
                        <ScrollLink to={item.to} smooth={true} duration={1800}>
                          {item.buttonText}
                        </ScrollLink>
                      </Button>
                    </MotionRight>
                  </Grid>
                  <Grid item xs={12} lg={7}
                    sx={{
                      padding: 0,
                      margin: 0,
                      ['@media (max-width: 600px)']: {
                        display: 'none',
                      },
                    }}
                  >
                    {/* <Box className='boxCircle'>
                      <Circle params={{ lang: params.lang }} />
                    </Box> */}
                    <LargeView params={{ lang: params.lang }} />
                  </Grid>
                  <Grid item xs={12} lg={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      ['@media (max-width: 600px)']: {
                        display: 'none',
                      },
                    }}
                    mt={4}
                  >
                    <Box className="mediaSocialHeroBox">
                      <MediaSocial />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container sx={{ bottom: 75, position: 'fixed' }}>
                  <Grid item xs={12} sx={{ marginTop: '-120px' }}>
                    <Box className="mobileBox">
                      <MobileView params={{ lang: params.lang }} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </SwiperSlide>
        ))
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: { xs: '-70px', md: '-90px' },
          marginBottom: { xs: '30px', md: '60px' },
          left: { xs: '10px', md: '150px' },
          position: 'relative',
          zIndex: 3
        }}
      >
        {
          dataSlider.map((item, index) => (
            <PaginationTopBanner key={index} index={index} active={activeIndex} />
          ))
        }
      </Box>
    </Swiper >
  );
};

export default SliderTopBanner;