import { getDictionary } from '@/dictionaries';
import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MotionDown from '@/lib/framerMotion/motionDown';

const MobileView = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', }}>
        <MotionDown duration={1.4}>
          <Box className="" sx={{ textAlign: 'center', color: '#D2DDE1', width: '80px', borderRight: '2px solid #d2d2d2', padding: '0 8px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              &gt; 6 {dictionary.million}
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText1}</Typography>
          </Box>
        </MotionDown>
        <MotionDown duration={1.5}>
          <Box className="" sx={{ textAlign: 'center', color: '#D2DDE1', width: '80px', borderRight: '2px solid #d2d2d2', padding: '0 8px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              &gt; 100
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText2}</Typography>
          </Box>
        </MotionDown>
        <MotionDown duration={1.6}>
          <Box className="" sx={{ textAlign: 'center', color: '#D2DDE1', width: '80px', borderRight: '2px solid #d2d2d2', padding: '0 8px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              4
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText3}</Typography>
          </Box>
        </MotionDown>
        <MotionDown duration={1.7}>
          <Box className="" sx={{ textAlign: 'center', color: '#D2DDE1', width: '80px', padding: '0 8px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              &gt; 12
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText4}</Typography>
          </Box>
        </MotionDown>
      </Box>
    </Box>
  )
}

export default MobileView
