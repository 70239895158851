import { getDictionary } from '@/dictionaries';
import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MotionDown from '@/lib/framerMotion/motionDown';

const LargeView = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);
  return (
    <Box sx={{ display: 'flex', marginLeft: '-100px' }}>
      <MotionDown duration={1.4}>
        <Box className=""
          sx={{
            textAlign: 'center',
            color: '#D2DDE1',
            width: '160px',
            height: '160px',
            padding: '0 8px',
            borderRight: '2px solid #d2d2d2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
              &gt; 6 {dictionary.million}
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText1}</Typography>
          </Box>
        </Box>
      </MotionDown>
      <MotionDown duration={1.5}>
        <Box className="" sx={{
          textAlign: 'center',
          color: '#D2DDE1',
          width: '160px',
          height: '160px',
          padding: '0 8px',
          borderRight: '2px solid #d2d2d2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
          <Box>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
              &gt; 100
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText2}</Typography>
          </Box>
        </Box>
      </MotionDown>
      <MotionDown duration={1.6}>
        <Box className="" sx={{
          textAlign: 'center',
          color: '#D2DDE1',
          width: '160px',
          height: '160px',
          padding: '0 8px',
          borderRight: '2px solid #d2d2d2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
          <Box>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
              4
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText3}</Typography>
          </Box>
        </Box>
      </MotionDown>
      <MotionDown duration={1.7}>
        <Box className="" sx={{
          textAlign: 'center',
          color: '#D2DDE1',
          width: '160px',
          height: '160px',
          padding: '0 8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
          <Box>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
              &gt; 12
            </Typography>
            <Typography className='textCircleCarousel'>{dictionary.circleText4}</Typography>
          </Box>
        </Box>
      </MotionDown>
    </Box>
  )
}

export default LargeView
