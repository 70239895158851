import { Box } from '@mui/material';
import React from 'react'
import { useSwiper } from 'swiper/react';
import MotionDown from '@/lib/framerMotion/motionDown';
interface Proops {
  index: number;
  active: number;
}

const PaginationTopBanner = ({ index, active }: Proops) => {
  const swiper = useSwiper();
  return (
    <MotionDown duration={1.4}>
      <Box
        sx={{
          backgroundColor: index === active ? '#8DABB6' : '#999999',
          width: { xs: '32px', md: '80px' },
          height: '4px',
          cursor: 'pointer',
          m: '0px 5px',
          borderRadius: '10px'

        }}
        onClick={() => swiper.slideTo(index)}
      />
    </MotionDown>
  )
}

export default PaginationTopBanner